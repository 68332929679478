// ================ Common Buttons Start ==================

.cx-btn-dark {
  background-color: var(--primary_dark_bg_34528A);
  color: var(--text_white);
  border-radius: 32px;
  padding: 8px 30px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 26px 0px #00000040;
}

.cx-btn-dark-2 {
  background-color: var(--primary_dark_bg_34528A);
  color: var(--text_white);
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 4px 26px 0px #00000040;
}

.btn-width-100 {
  width: 100%;
}

.btn-width-50 {
  width: 50%;
}

.btn-width-45 {
  width: 45%;
}

.btn-height {
  height: 53px;
}

.btn-height1 {
  height: 40px !important;
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.cx-btn-light {
  background-color: var(--text_white);
  color: var(--primary_dark_bg_34528A);
  border-radius: 32px;
  height: 52px;
  border: 1px solid var(--primary_dark_bg_34528A);
  //   font-size: 17px;
}

.link_common {
  font-size: 23px;
  font-weight: 600;
  color: var(--primary_dark_bg_34528A);
  text-decoration: none;
  margin-left: 10px;
}

label {
  font-size: 13px;
  font-weight: 500;
  color: var(--text_label_gray);
}

.form-control {
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--text_placeholder);
  }
}

a {
  text-decoration: none;
}

.tdName {
  text-transform: capitalize;
}


::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  display: none;

}

::-webkit-scrollbar-thumb {
  background: var(--primary_dark_bg_34528A);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

.loader-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  // background: white;
  color: red;

}

.scheule_tody {
  font-size: 13px;
  display: flex;
  justify-content: center // margin-left: 161px;
    // margin-top: 24px;
}


.event1 {
  // background-color: rgba(143, 67, 0, 1);
  background: rgba(246, 239, 233, 1);

  color: rgba(246, 239, 233, 1);

}

.breakEvent {
  background-color: rgba(115, 113, 110, 1);
}

.event2 {
  background-color: rgba(213, 78, 33, 1);
}

.event3 {
  background-color: rgba(66, 139, 202, 1);
}

.event4 {
  background-color: rgba(0, 193, 158, 1);
}

.event5 {
  background: rgba(246, 239, 233, 1);
  color: rgba(213, 78, 33, 1);
  ;
}

.calendar-section {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;

  // margin-top: 4rem;
  .holiday-and-legend {
    margin-top: 20px;

    .title {
      font-size: 14px;
      font-weight: 700;
    }

    .holidays-section {
      padding: 20px;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0 2px 10px 0 var(--gray_02_LightMode);
      background-color: #f6f2e5;

      .holiday-date {
        color: var(--Warning_text_07BE24_lightMode);
        margin-right: 10px;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }

    .legend-section {
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 10px 0 var(--gray_02_LightMode);

      .capsule-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0%;

        .capsule {
          padding: 5px 0px;
          display: flex !important;
          flex-wrap: wrap;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: 0%;

          .legend-color-capsule {
            width: 2.4rem !important;
            height: 1rem !important;
            content: "";
            border-radius: 10px;
            vertical-align: middle;
          }

          .color-today {
            background-color: #53b7e8;
          }

          .color-leave-applied {
            background-color: #ffa903;
          }

          .color-approved-leave {
            background-color: #04b520;
          }

          .color-week-off {
            background-color: var(--blue_deeplight_EEF8FD);
          }

          .color-reported-time {
            background-color: #016da1;
          }

          .color-holiday {
            background-color: #ff0000;
          }

          .color-project-start {
            background-color: #e4612a;
          }

          .color-project-end {
            background-color: #ffe600;
          }

          .legend-label {
            padding-left: 10px;
            font-size: 0.8rem;
            white-space: nowrap;

            @media (min-width: 1024) {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

.iconHolder {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #fff;
  top: 115px;
  left: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 0px 20px 0px;

  .editIcon {
    height: 15px;
    width: 15px;
  }
}

// ================ Common Buttons End ==================