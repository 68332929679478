.lightMode {
  // BACKGROUND-COLORS VARIABLES
  --primary_dark_bg_34528A: #34528a;
  --secondary_light_bg_E9F5FB: #e9f5fb;
  --bg_White: #fff;
  --bg_yellow: #f4b400;
  --bg_light_green: #21c896;
  --bg_blue: #0071ff;
  --bg_orange: #ff9900;
  --bg_pink: #cd18d0;
  --bg_purple: #5500df;
  --bg_dark_green: #01b529;
  --bg_dark_red: #d0182e;
  --bg_light_red: #ee5d50;
  --bg_light_gray: #f2f5ff;
  --bg_light_yellow: #ffce2040;
  --bg_extra_light_red: #ee5d5080; 

  //  TEXT-COLOR VARIABLES
  
  --text_dark_blue_042486: #042486;
  --text_dark_blue_34528A: #34528a;
  --text_light_table_heading_A3AED0: #a3aed0;
  --text_white: #fff;
  --text_light_F6F6F6: #F6F6F6;
  --text_black: #000;
  --text_light_menu_819FD8: #819fd8;
  --text_green_21C896: #21c896; 
  --text_blue_0071FF: #0071ff;
  --text_gray_ADADAD: #adadad;
  --text_gray_646D82: #646D82;
  --text_gray_8F9BBA: #8F9BBA;
  --text_label_gray:#999999;
  --text_placeholder:#000842;
  --text_yellow_E1B103: #e1b103;
}

// For Future use this variables are created
.darkMode {
}
