.main-wrapper {
  background: var(--secondary_light_bg_E9F5FB);
}

.capital {
  text-transform: capitalize;
}

#cx-main {
  padding: 120px 20px 20px 20px;
  margin-left: 270px;
  height: 100vh;
  overflow-y: auto;
}

#scroll_insideThe_Padding_tabel {
  height: calc(100vh - 258px) !important;
  overflow-y: auto;

  .all-vehical-body {
    height: auto !important;
  }
}

#card-main-bg-next {
  background-color: var(--bg_White);
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 20px;

  .table-wrapper {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    height: calc(100vh - 245px);
  }

  .app-details-wrapper .left {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .left .photo-video-wrapper {
    margin-bottom: 0;
  }

  .app-details-wrapper {
    margin-top: 0;
  }
}

#appointment-customer-left {
  height: calc(100vh - 276px);
  overflow-y: auto;

  .value {
    text-transform: capitalize;
  }
}

.visits-tab-wrapper {
  height: calc(100vh - 298px);
  overflow-y: auto;
}

.invoices-tab-wrapper {
  height: calc(100vh - 298px);
  overflow-y: auto;
}

.appointment-wrapper {
  #ChattingVideoComp {
    .send-text-sec {
      .wrap-btn-send {
        display: flex;
        background-color: #e9f5fb;
        padding: 5px;
        border-radius: 80px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #34528a;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          border: none;
        }

        .form-control {
          background-color: transparent;
          border: none;

          &:focus {
            box-shadow: none;
          }

          &::placeholder {
            color: #8d8f98;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22.15px;
            /* 158.218% */
          }
        }
      }
    }

    h4 {
      color: #042486;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.32px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f6f6f6;
    }

    .left-video {
      border-radius: 20px;
      background-color: var(--bg_White);
      padding: 20px;
      min-height: calc(100vh - 170px) // height: 71% !important

    }

    .right-chating {
      border-radius: 20px;
      background-color: var(--bg_White);
      padding: 10px;

      .chatting-section {
        height: calc(100vh - 278px);
        margin: 15px 0 0;
        overflow-y: auto;
      }

      .message-main {
        margin-bottom: 10px;

        .receive-msg {
          display: flex;
          margin-bottom: 5px;

          .msg-with-img {
            margin-right: 5px;

            img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
            }
          }

          .right-receive-msg {
            .msg-bg {
              border-radius: 7px;
              background: var(--Light-Blue, #e9f5fb);
              padding: 10px;
            }

            display: block;
            text-align: left;

            .senderName {
              color: var(--Dashboard-Light-headers, #a3aed0);
              font-family: Poppins;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
            }

            .receive-msg-main {
              .msg-text {
                color: var(--Vet, #34528a);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                margin-bottom: 0;
                font-weight: 500;
              }
            }
          }
        }

        .send-msg {
          text-align: right;
          display: flex;
          flex-direction: row-reverse;
          margin-bottom: 5px;

          .msg-with-img {
            img {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              margin-left: 5px;
            }
          }

          .send-msg-main {
            .msg-bg {
              border-radius: 10px;
              background: var(--Light-Blue, #e9f5fb);
              padding: 10px;
            }

            .senderName {
              color: var(--Dashboard-Light-headers, #a3aed0);
              font-family: Inter;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
            }

            .msg-text {
              margin-bottom: 0;
              color: var(--Vet, #34528a);
              font-family: Inter;
              font-size: 15.301px;
              font-style: normal;
              font-weight: 500;
            }
          }
        }

        .time-date-sms-send {
          display: flex;
          flex-direction: row-reverse;
          color: var(--Dashboard-Light-headers, #a3aed0);
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          margin-top: 5px;
        }

        .time-date-sms-recieve {
          color: var(--Dashboard-Light-headers, #a3aed0);
          font-family: Poppins;
          font-size: 10px;
          font-style: normal;
          margin-top: 5px;
          font-weight: 400;
        }
      }
    }

    .left-video {
      .video-section {
        :nth-child(2) {
          background-color: #34528a !important;
        }

        :nth-child(1) {
          div {
            &:nth-child(1) {
              div {
                &:nth-child(1) {
                  div {
                    &:nth-child(1) {
                      flex: 1 1 0% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

p.info_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--primary_dark_bg_21C896);
}

#app-list-page-wrapper,
#app-list-page-wrapper1,
#app-list-page-wrapper2 {
  background-color: var(--bg_White);
  padding: 10px 20px;
  border-radius: 20px;

  .table-wrapper {
    height: calc(100vh - 250px);
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
  }
}

.heightCalc {
  height: calc(100vh - 150px) !important;
  padding: 20px;

  .userCallSec {
    width: 100%;
    background: var(--Light-Blue, #e9f5fb);
    height: 600px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
}

.text-center {
  margin: auto;
}


.p-dialog {
  border: none;
  box-shadow: none;
}

.p-dialog .p-dialog-header {
  display: none;
}

.p-dialog .p-dialog-content {
  padding-top: 1.5rem;
  border-radius: 20px;
}

.p-dialog-enter-done {
  transform: translate(-15px, 20px) !important;
}

.p-dialog .p-dialog-header,
.p-dialog .p-dialog-content {
  background: #fff;
  color: #43beba !important;

  .insideCallSec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }

    .detailsSec {
      p {
        font-size: 15px;

      }
    }

    .buttonsFlex {
      margin-top: 20px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;

      .acceptBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-color: green;
      }

      .rejectBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}

.heightCalc {
  height: calc(68vh - 78px) !important;
  padding: 20px;

  .userCallSec {
    width: 120%;
    background: var(--Light-Blue, #e9f5fb);
    height: 800px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
}

.p-dialog .p-dialog-content {
  background-color: white !important;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: white;
  color: black;
}

// / STyles for Notification Screen\
.notificationWrapper {
  background-color: #fff;
  min-height: calc(100vh - 110px);
  padding: 20px;
  border-radius: 20px;

  .insideTable {
    width: 100%;

    .tableNoti {
      width: 100%;

      thead {
        tr {
          border-bottom: .5px solid #EEF3FC;

          th {
            position: sticky;
            top: 0px;
            background-color: #fff;
            color: #A3AED0;
            font-size: 12px;
            padding-bottom: 5px;
            // width: 20%;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px 10px 10px 0px;
            font-size: 12px;
            color: #042486;

            span {
              cursor: pointer;
              font-size: 15px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.tittleHead {
  padding: 10px;
}

.tittleModal {
  color: #042486 !important;
}

.formBox {
  padding: 10px 20px;

  .formBody {
    margin-bottom: 15px;
  }

  .form-select {
    &:focus {
      box-shadow: none;
    }
  }

  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
}

.buttonBox {
  text-align: end;

  .submitBtn {
    border: none;
    background-color: #042486;
    color: #fff;
    height: 40px;
    width: 140px;
    border-radius: 12px;
  }
}