* {
    font-family: 'Poppins', sans-serif !important;
}
@import url(./variable.scss);
@import url(./sidebar.scss);
@import url(./auth.scss);
@import url(./header.scss);
@import url(./global.scss);
@import url(./style.scss);
@import url(./appointment.scss);
@import url(./calender.scss);
@import url(./Dashboard.scss);
@import url(./reports.scss);
@import url(./myprofile.scss);
@import url(./responsive.scss);
@import url(./responsive2.scss);
